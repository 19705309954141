export default [
  {
    path: '/marketing/request/add',
    name: 'MarketingRequestAdd',
    component: () => import('@/views/Marketing/Requests/Add.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          to: '/marketing/request',
          text: 'Taleplerim',
        },
        {
          text: 'Talep Et',
          active: true,
        },
      ],
      resource: 'MARKETING_REQUEST_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/request',
    name: 'MarketingRequest',
    component: () => import('@/views/Marketing/Requests/Index.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          text: 'Taleplerim',
          active: true,
        },
      ],
      resource: 'MARKETING_REQUEST',
      action: 'read',
    },
  },
  {
    path: '/marketing/request/view/:id',
    name: 'MarketingRequestView',
    component: () => import('@/views/Marketing/Requests/View.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          to: '/marketing/request',
          text: 'Taleplerim',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'MARKETING_REQUEST',
      action: 'read',
    },
  },
  {
    path: '/marketing/request/list',
    name: 'MarketingRequestList',
    component: () => import('@/views/Marketing/Requests/RequestList.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          text: 'Gelen Talepler',
          active: true,
        },
      ],
      resource: 'MARKETING_REQUEST_LIST',
      action: 'read',
    },
  },
]
