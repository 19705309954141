import moment from 'moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    uploading: false,
    dataItem: {
      id: null,
      filename: null,
      upload_file: null,
      notes: null,
      id_com_damage_tracking_status: null,
      id_com_damage_tracking: null,
      id_com_damage_tracking_line: null,
      id_com_repair_status: null,
      id_com_damage_car_repair: null,
      id_com_user: null,
      submitStatus: false,
    },
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    saveDataStatus(state) {
      return state.saveData
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        filename: null,
        upload_file: null,
        notes: null,
        id_com_damage_tracking_status: null,
        id_com_damage_tracking: null,
        id_com_damage_tracking_line: null,
        id_com_repair_status: null,
        id_com_damage_car_repair: null,
        id_com_user: null,
        submitStatus: false,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/damage_tracking_documents', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_damage_tracking_documents.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/damage_tracking_documents', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      // const data = JSON.stringify(postData)
      // axiosIns
      //   .post('/data/damage_tracking_documents/saveData', data)
      //   .then(response => {
      //     commit('SAVE_DATA_STATUS', response.data)
      //   })
      //   .catch(error => console.error(error))
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('filename', postData.filename)
      formData.append('notes', postData.notes)
      formData.append('id_com_damage_tracking_status', postData.id_com_damage_tracking_status)
      formData.append('id_com_damage_tracking', postData.id_com_damage_tracking)
      formData.append('id_com_repair_status', postData.id_com_repair_status)
      formData.append('id_com_damage_car_repair', postData.id_com_damage_car_repair)
      formData.append('id_com_user', postData.id_com_user)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post('/data/damage_tracking_documents/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/damage_tracking_documents/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
