import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      detail: [],
      report: [],
    },
    filterData: {
      sdate: null,
      edate: null,
      service_worker: null,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    RESET_REPORT_DATA(state) {
      state.reportData = {
        detail: [],
        report: [],
      }
    },
    RESET_REPORT_FILTER_DATA(state) {
      state.filterData = {
        sdate: null,
        edate: null,
        service_worker: null,
      }
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/service/service_work_orders', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
