import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataItem: {
      order_number: null,
    },
    dataCounts: 0,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    RESET_CANCEL_LIST(state) {
      state.dataItem = {
        order_number: null,
      }
    },
  },
  actions: {
    cancelForm({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/ManagerTools/UserOperations/cancelSaleOrderForm', data)
        .then(response => {
          commit('SET_DATA_LOADING', false)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
