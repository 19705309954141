import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    uploading: false,
    dataItem: {
      id: null,
      filename: null,
      upload_file: null,
      id_com_customer: null,
      id_com_customer_document_type: '1',
      id_com_user: null,
      submitStatus: false,
    },
    addDataItem: {
      id: null,
      filename: null,
      upload_file: null,
      name: null,
      phone: null,
      id_com_user: null,
      id_com_customer_document_type: '1',
      submitStatus: false,
    },
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    getAddData(state) {
      return state.addDataItem
    },
    saveDataStatus(state) {
      return state.saveData
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_ITEM_ADDDATA(state, data) {
      state.addDataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        filename: null,
        upload_file: null,
        id_com_customer: null,
        id_com_customer_document_type: '1',
        id_com_user: null,
        submitStatus: false,
      }
    },
    SET_ADDDATA_RESET(state) {
      state.dataItem = {
        id: null,
        filename: null,
        upload_file: null,
        name: null,
        phone: null,
        id_com_user: null,
        id_com_customer_document_type: '1',
        submitStatus: false,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/customer_document', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_customer_document.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/customer_document', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('filename', postData.filename)
      formData.append('id_com_user', postData.id_com_user)
      formData.append('id_com_customer', postData.id_com_customer)
      formData.append('id_com_customer_document_type', postData.id_com_customer_document_type)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post('/data/customer_document/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    AddCustomer({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('filename', postData.filename)
      formData.append('id_com_user', postData.id_com_user)
      formData.append('name', postData.name)
      formData.append('phone', postData.phone)
      formData.append('id_com_customer_document_type', postData.id_com_customer_document_type)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post('/data/customer_document/addCustomer', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          commit('SET_UPLOADING', false)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/customer_document/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
