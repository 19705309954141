import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    filterParams: {
      sdate: `${moment().format('YYYY-MM')}-01`,
      edate: moment().format('YYYY-MM-DD'),
    },
    managerGprFilterParams: {
      sdate: `${moment().format('YYYY-MM')}-01`,
      edate: moment().format('YYYY-MM-DD'),
      id_com_brand: null,
      id_com_model: null,
      chasis: null,
    },
    managerGprStockFilterParams: {
      sdate: null,
      edate: null,
      id_com_brand: null,
      id_com_model: null,
      chasis: null,
    },
    financialCosts: [],
    stockFinancialCosts: [],
    loadFinancialCosts: false,
    loadStockFinancialCosts: false,
    loadSyncData: false,
    paymentsDetail: {
      dataList: [],
      modalStatus: false,
    },
    paymentsDetailLoading: false,
  },
  getters: {
    filterParams(state) {
      return state.filterParams
    },
    getFinancialFilterParams(state) {
      return state.managerGprFilterParams
    },
    getStockFinancialFilterParams(state) {
      return state.managerGprStockFilterParams
    },
    getFinancialCosts(state) {
      return state.financialCosts
    },
    getStockFinancialCosts(state) {
      return state.stockFinancialCosts
    },
    getFinancialLoading(state) {
      return state.loadFinancialCosts
    },
    getStockFinancialLoading(state) {
      return state.loadStockFinancialCosts
    },
    getSyncDataLoading(state) {
      return state.loadSyncData
    },
    getPaymentsDetail(state) {
      return state.paymentsDetail
    },
    getPaymentsDetailLoading(state) {
      return state.paymentsDetailLoading
    },
  },
  mutations: {
    FINANCIAL_COSTS(state, data) {
      state.financialCosts = data
    },
    STOCK_FINANCIAL_COSTS(state, data) {
      state.stockFinancialCosts = data
    },
    FINANCIAL_LOADING(state, data) {
      state.loadFinancialCosts = data
    },
    SYNC_DATA_LOADING(state, data) {
      state.loadSyncData = data
    },
    STOCK_FINANCIAL_LOADING(state, data) {
      state.loadStockFinancialCosts = data
    },
    SET_PAYMENTS_DETAIL(state, data) {
      state.paymentsDetail.dataList = data
    },
    PAYMENTS_DETAIL_LOADING(state, data) {
      state.paymentsDetailLoading = data
    },
  },
  actions: {
    financialCosts({ commit }, params) {
      const data = JSON.stringify(params)
      commit('FINANCIAL_LOADING', true)
      axiosIns
        .post('/reports/sales/manager_gpr/financialCost', data)
        .then(response => {
          commit('FINANCIAL_COSTS', response.data.financialCosts)
          commit('FINANCIAL_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    stockFinancialCosts({ commit }, params) {
      const data = JSON.stringify(params)
      commit('STOCK_FINANCIAL_LOADING', true)
      axiosIns
        .post('/reports/sales/manager_gpr/financialCost', data)
        .then(response => {
          commit('STOCK_FINANCIAL_COSTS', response.data.financialCosts)
          commit('STOCK_FINANCIAL_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    reportDataSync({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SYNC_DATA_LOADING', true)
      return axiosIns
        .post('/cronjobs/MssqlAracStokSatis/mssqlAracStockAll', data)
        .then(response => {
          commit('SYNC_DATA_LOADING', false)
          return response.data
        })
        .catch(error => console.error(error))
    },
    paymentsDetail({ commit }, params) {
      const data = JSON.stringify(params)
      commit('PAYMENTS_DETAIL_LOADING', true)
      axiosIns
        .post('/reports/sales/manager_gpr/paymentsDetail', data)
        .then(response => {
          commit('SET_PAYMENTS_DETAIL', response.data.data)
          commit('PAYMENTS_DETAIL_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
