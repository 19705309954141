export default [
  {
    path: '/insurance/reminders',
    name: 'InsuranceReminder',
    component: () => import('@/views/Insurance/Reminder/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Müşteri Aramaları',
          active: true,
        },
      ],
      resource: 'INSURANCE_REMINDER',
      action: 'read',
    },
  },
]
