export default [
  {
    path: '/damage-tracking',
    name: 'DamageTracking',
    component: () => import('@/views/Damage_tracking/index.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_LIST',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/search',
    name: 'DamageTrackingSearch',
    component: () => import('@/views/Damage_tracking/SearchCustomer.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_ADD',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/add/:id',
    name: 'DamageTrackingAdd',
    component: () => import('@/views/Damage_tracking/Add.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_ADD',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/view/:id',
    name: 'DamageTrackingView',
    component: () => import('@/views/Damage_tracking/View.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_VIEW',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/edit/:id',
    name: 'DamageTrackingEdit',
    component: () => import('@/views/Damage_tracking/Edit.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_EDIT',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/lines/add/:id',
    name: 'DamageTrackingLinesAdd',
    component: () => import('@/views/Damage_tracking/lines/Add.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_ADD',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/lines/edit/:id',
    name: 'DamageTrackingLinesEdit',
    component: () => import('@/views/Damage_tracking/lines/Edit.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_EDIT',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/repair/add/:id',
    name: 'DamageTrackingRepairAdd',
    component: () => import('@/views/Damage_tracking/Repair/Add.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_ADD',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/repair/edit/:id',
    name: 'DamageTrackingRepairEdit',
    component: () => import('@/views/Damage_tracking/Repair/Edit.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_EDIT',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/document/add/:id',
    name: 'DamageTrackingDocumentAdd',
    component: () => import('@/views/Damage_tracking/document/Add.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_ADD',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking/document/edit/:id',
    name: 'DamageTrackingDocumentEdit',
    component: () => import('@/views/Damage_tracking/document/Edit.vue'),
    meta: {
      pageTitle: 'Hasar Takibi',
      breadcrumb: [
        {
          text: 'Hasar Takibi',
          to: '/damage-tracking',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DAMAGE_TRACING_EDIT',
      action: 'read',
    },
  },
]
