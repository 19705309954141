export default [
  {
    path: '/sparepart-stock-cards',
    name: 'SparepartStockCards',
    component: () => import('@/views/Spareparts/StockCards/Index.vue'),
    meta: {
      pageTitle: 'Yedek Parça Stok Kartları',
      breadcrumb: [
        {
          text: 'Listele',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_CARDS',
      action: 'read',
    },
  },
  {
    path: '/sparepart-stock-cards/add',
    name: 'SparepartStockCardsAdd',
    component: () => import('@/views/Spareparts/StockCards/Add.vue'),
    meta: {
      pageTitle: 'Yedek Parça Stok Kartları',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-stock-cards',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_CARDS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sparepart-stock-cards/edit/:id',
    name: 'SparepartStockCardsEdit',
    component: () => import('@/views/Spareparts/StockCards/Edit.vue'),
    meta: {
      pageTitle: 'Yedek Parça Stok Kartları',
      breadcrumb: [
        {
          text: 'Listele',
          to: '/sparepart-stock-cards',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_CARDS_EDIT',
      action: 'read',
    },
  },
]
