import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {},
    filterData: {
      keywords: null,
      sdate: null,
      edate: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: true,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    filterData(state) {
      return state.filterData
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        keywords: null,

        sdate: null,
        edate: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/hr/careerform', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      axiosIns
        .post('/hr/careerform/view', { id })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    deleteData({ commit }, id) {
      return axiosIns
        .post('/data/sparepart_orders/deleteData', { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    resetDataFilter({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}
