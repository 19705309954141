export default [
  // Sales
  {
    path: '/spareparts',
    name: 'SpareParts',
    component: () => import('@/views/Spareparts/Index.vue'),
    meta: {
      pageTitle: 'Yedek Parça',
      breadcrumb: [
        {
          text: 'Siparişler',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/spareparts/view/:interview_number',
    name: 'SparePartsView',
    component: () => import('@/views/Spareparts/View.vue'),
    meta: {
      pageTitle: 'Yedek Parça',
      breadcrumb: [
        {
          text: 'Siparişler',
          to: '/spareparts',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SPAREPART_ORDER_VIEW',
      action: 'read',
    },
  },
  {
    path: '/tirehotel',
    name: 'TireHotel',
    component: () => import('@/views/Spareparts/TireHotel/Index.vue'),
    meta: {
      pageTitle: 'Lastik Oteli',
      breadcrumb: [
        {
          text: 'Lastik Oteli',
          active: true,
        },
      ],
      resource: 'SPAREPART_TIRE_HOTEL_LIST',
      action: 'read',
    },
  },
  {
    path: '/tirehotel/edit/:id',
    name: 'TireHotelEdit',
    component: () => import('@/views/Spareparts/TireHotel/Edit.vue'),
    meta: {
      pageTitle: 'Lastik Oteli',
      breadcrumb: [
        {
          text: 'Lastik Oteli',
          to: '/tirehotel',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SPAREPART_TIRE_HOTEL_EDIT',
      action: 'read',
    },
  },
  {
    path: '/tirehotel/change/:id',
    name: 'TireHotelChange',
    component: () => import('@/views/Spareparts/TireHotel/Change.vue'),
    meta: {
      pageTitle: 'Lastik Oteli',
      breadcrumb: [
        {
          text: 'Lastik Oteli',
          to: '/tirehotel',
          active: false,
        },
        {
          text: 'Değiştir',
          active: true,
        },
      ],
      resource: 'SPAREPART_TIRE_HOTEL_EDIT',
      action: 'read',
    },
  },
  {
    path: '/tirehotel/search',
    name: 'TireHotelSearch',
    component: () => import('@/views/Spareparts/TireHotel/Search.vue'),
    meta: {
      pageTitle: 'Lastik Oteli',
      breadcrumb: [
        {
          text: 'Lastik Oteli',
          to: '/tirehotel',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SPAREPART_TIRE_HOTEL_ADD',
      action: 'read',
    },
  },
  {
    path: '/tirehotel/add/:id',
    name: 'TireHotelAdd',
    component: () => import('@/views/Spareparts/TireHotel/Add.vue'),
    meta: {
      pageTitle: 'Lastik Oteli',
      breadcrumb: [
        {
          text: 'Lastik Oteli',
          to: '/tirehotel',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SPAREPART_TIRE_HOTEL_ADD',
      action: 'read',
    },
  },
]
