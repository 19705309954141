import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      data: [],
    },
    filterData: {
      sdate: null,
      edate: null,
      id_com_location: null,
      id_com_brand: null,
      crmCounts: null,
      start: 0,
      limit: 20,
    },
    loadingReport: null,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
    crmCounts(state) {
      return state.crmCounts
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_USERS_DATA(state, data) {
      state.reportUsers = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    CRM_COUNT(state, data) {
      state.crmCounts = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/crm/TracingPriceRequests', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
          commit('CRM_COUNT', response.data.count)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
