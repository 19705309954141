import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    istatistics: {
      calls: 0,
      pending: 0,
      total: 0,
    },
    filterData: {
      sdate: null,
      edate: null,
      status: null,
      customer: null,
      id_com_user: null,
    },
    dataCounts: 0,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataIstatistics(state) {
      return state.istatistics
    },
    dataCounts(state) {
      return state.dataCounts
    },
    getFilterData(state) {
      return state.filterData
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ISTATISTICS(state, data) {
      state.istatistics = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    getReport({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/insurance/reminder', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_ISTATISTICS', response.data.istatistics)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
