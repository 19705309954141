export default [
  {
    path: '/portal/meals',
    name: 'PortalMeals',
    component: () => import('@/views/Portal/Meals/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Yemek Menüsü',
          active: true,
        },
      ],
      resource: 'PORTAL_MEALS_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/meals/add',
    name: 'PortalMealAdd',
    component: () => import('@/views/Portal/Meals/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Yemek Menüsü',
          to: '/portal/meals',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_MEALS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/meals/batch-add',
    name: 'PortalMealAdd',
    component: () => import('@/views/Portal/Meals/BatchAdd.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Yemek Menüsü',
          to: '/portal/meals',
          active: false,
        },
        {
          text: 'Toplu Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_MEALS_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/meals/edit/:id',
    name: 'PortalMealEdit',
    component: () => import('@/views/Portal/Meals/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Yemek Menüsü',
          to: '/portal/meals',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_MEALS_EDIT',
      action: 'read',
    },
  },
]
