import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    models: [],
    modelsCount: 0,
    model: {},
    modelSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getModels(state) {
      return state.models
    },
    getModelsCount(state) {
      return state.modelsCount
    },
    getModel(state) {
      return state.model
    },
    getModelSaveStatus(state) {
      return state.modelSave
    },
  },
  mutations: {
    MODELS_LIST(state, data) {
      state.models = data
    },
    MODEL_VIEW(state, data) {
      state.model = data
    },
    MODELS_COUNT(state, data) {
      state.modelsCount = data
    },
    MODEL_SAVE(state, data) {
      state.modelSave = data
    },
  },
  actions: {
    modelsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/models', data)
        .then(response => {
          commit('MODELS_LIST', response.data.data)
          commit('MODELS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    modelView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_model.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/models', data)
        .then(response => {
          commit('MODEL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    modelSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/models/saveData', data)
        .then(response => {
          commit('MODEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    modelDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/models/deleteData', data)
        .then(response => {
          commit('MODEL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
