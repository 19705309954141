import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    i2Sales: [],
    i2SalesCount: 0,
    i2Sale: {},
    i2SaleSave: {
      message: null,
      status: null,
      id: null,
    },
    quickSearch: {
      keyword: '',
    },
  },
  getters: {
    getI2Sales(state) {
      return state.i2Sales
    },
    getI2SalesCount(state) {
      return state.i2SalesCount
    },
    getI2Sale(state) {
      return state.i2Sale
    },
    getI2SaleSaveStatus(state) {
      return state.i2SaleSave
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
  },
  mutations: {
    SET_I2SALES(state, data) {
      state.i2Sales = data
    },
    SET_I2SALE(state, data) {
      state.i2Sale = data
    },
    SET_I2SALES_COUNT(state, data) {
      state.i2SalesCount = data
    },
    SET_I2SALE_SAVE(state, data) {
      state.i2SaleSave = data
    },
  },
  actions: {
    i2Sales({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/i2sales', data)
        .then(response => {
          commit('SET_I2SALES', response.data.data)
          commit('SET_I2SALES_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    i2Sale({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_i2sale.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/i2sales', data)
        .then(response => {
          commit('SET_I2SALE', response.data.data)
        })
        .catch(error => console.error(error))
    },
    i2SaleSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/i2sales/saveData', data)
        .then(response => {
          commit('SET_I2SALE_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
