export default [
  {
    path: '/i2sales',
    name: 'I2Sales',
    component: () => import('@/views/I2Sales/Index.vue'),
    meta: {
      pageTitle: 'İkinci El Satış Föyleri',
      breadcrumb: [
        {
          text: 'İkinci El Satış Föyleri',
          active: true,
        },
      ],
      resource: 'I2_SALE_ORDER_LIST',
      action: 'read',
    },
  },
  {
    path: '/i2sales/view/:id',
    name: 'I2SalesView',
    component: () => import('@/views/I2Sales/View.vue'),
    meta: {
      pageTitle: 'İkinci El Satış Föyleri',
      breadcrumb: [
        {
          text: 'İkinci El Satış Föyleri',
          to: '/i2sales',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'I2_SALE_ORDER_VIEW',
      action: 'read',
    },
  },
]
