export default [
  {
    path: '/webforms/i2saleform',
    name: 'I2saleform',
    component: () => import('@/views/Webforms/I2saleform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Satmak İstiyorum',
          active: true,
        },
      ],
      resource: 'WEBFORM_I2SALEFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/i2saleform/view/:id',
    name: 'I2saleformView',
    component: () => import('@/views/Webforms/I2saleform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Satmak İstiyorum',
          to: '/webforms/i2saleform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_I2SALEFORM',
      action: 'read',
    },
  },
]
