export default [
  {
    path: '/meeting-calendar',
    name: 'MeetingCalendar',
    component: () => import('@/views/Meeting_calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Toplantı Odaları',
      breadcrumb: [
        {
          text: 'Kullanım Takvimi',
          active: true,
        },
      ],
      resource: 'MEETING_CALENDAR',
      action: 'read',
    },
  },
]
