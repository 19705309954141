export default [
  {
    path: '/brands',
    name: 'Brands',
    component: () => import('@/views/Defines/Brands/Index.vue'),
    meta: {
      pageTitle: 'İnallar Markaları',
      breadcrumb: [
        {
          text: 'İnallar Markaları',
          active: true,
        },
      ],
      resource: 'DEFINES_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/brands/add',
    name: 'BrandsAdd',
    component: () => import('@/views/Defines/Brands/Add.vue'),
    meta: {
      pageTitle: 'İnallar Markası Ekle',
      breadcrumb: [
        {
          text: 'İnallar Markaları',
          to: '/brands',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_BRANDS',
      action: 'read',
    },
  },
  {
    path: '/brands/edit/:id',
    name: 'BrandsEdit',
    component: () => import('@/views/Defines/Brands/Edit.vue'),
    meta: {
      pageTitle: 'İnallar Markası Güncelle',
      breadcrumb: [
        {
          text: 'İnallar Markaları',
          to: '/brands',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_BRANDS',
      action: 'read',
    },
  },
]
