export default [
  {
    path: '/surveys',
    name: 'BatchSurveys',
    component: () => import('@/views/Surveys/Index.vue'),
    meta: {
      pageTitle: 'Toplu Anketler',
      breadcrumb: [
        {
          text: 'Gönderim',
          active: true,
        },
      ],
      resource: 'BATCH_SURVEYS',
      action: 'read',
    },
  },

]
