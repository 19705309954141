export default [
  {
    path: '/reports/crm/requests',
    name: 'ReportsCrmRequests',
    component: () => import('@/views/Reports/Crm/Requests.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Başvurular',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/i2-surveys',
    name: 'ReportsCrmI2Surveys',
    component: () => import('@/views/Reports/Crm/I2Surveys.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: '2.Anketler',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_SURVEYS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/sale-surveys',
    name: 'ReportsCrmSurveys',
    component: () => import('@/views/Reports/Crm/SaleSurveys.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Sıfır Satış Anketler',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_SALE_SURVEYS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/request-durations',
    name: 'ReportsCrmRequestDurations',
    component: () => import('@/views/Reports/Crm/RequestDurations.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Dönüş Süreleri',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_REQUEST_DURATIONS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/tracing-price-requests',
    name: 'TracingPriceRequests',
    component: () => import('@/views/Reports/Crm/TracingPriceRequests.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Fiyat Talebi Randevu Adetleri',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_TRACING_PRICE_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/unreachable-requests',
    name: 'UnreachableRequests',
    component: () => import('@/views/Reports/Crm/UnreachableRequests.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Ulaşılamayan Müşteriler Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_UNREACHABLE_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/customer-kvkk-report',
    name: 'CustomersKvkkReport',
    component: () => import('@/views/Reports/Crm/CustomerKvkkReport.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Müşteri Kvkk Onay Durum Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_CUSTOMER_KVKK',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/request-excel',
    name: 'ReportsCrmRequestExcel',
    component: () => import('@/views/Reports/Crm/RequestsExcel.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Tüm Başvurular',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_REQUESTS_EXCEL',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/loyalty-program',
    name: 'ReportsLoyaltyProgram',
    component: () => import('@/views/Reports/Crm/LoyaltyProgram.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Sadakat Programı',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_LOYALTY_PROGRAM',
      action: 'read',
    },
  },
  {
    path: '/reports/crm/surveys-report',
    name: 'ReportsSurveys',
    component: () => import('@/views/Reports/Crm/SurveysReport.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Toplu Anketler',
          active: true,
        },
      ],
      resource: 'REPORTS_CRM_SURVEYS_REPORTS',
      action: 'read',
    },
  },
]
