export default [
  {
    path: '/portal/organization-charts',
    name: 'OrganizationCharts',
    component: () => import('@/views/Portal/OrganizationCharts/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Organizasyon Şeması',
          active: true,
        },
      ],
      resource: 'PORTAL_ORGANIZATION_CHARTS',
      action: 'read',
    },
  },
]
