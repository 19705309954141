import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    dataItem: {
      id: null,
      component_name: null,
      send_date: null,
      arrival_date: null,
      id_com_customer: null,
      id_com_cars: null,
      id_com_damage_repair_company: null,
      id_com_damage_repair_status: null,
      id_com_damage_tracking: null,
      id_com_user: null,
    },
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    saveDataStatus(state) {
      return state.saveData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        component_name: null,
        send_date: null,
        arrival_date: null,
        id_com_customer: null,
        id_com_cars: null,
        id_com_damage_repair_company: null,
        id_com_damage_repair_status: null,
        id_com_damage_tracking: null,
        id_com_user: null,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/damage_car_repair', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_damage_car_repair.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/damage_car_repair', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      // const data = JSON.stringify(postData)
      // axiosIns
      //   .post('/data/damage_car_repair/saveData', data)
      //   .then(response => {
      //     commit('SAVE_DATA_STATUS', response.data)
      //   })
      //   .catch(error => console.error(error))
      const formData = new FormData()

      formData.append('id', postData.id)
      formData.append('component_name', postData.component_name)
      formData.append('pdate', postData.pdate)
      formData.append('send_date', postData.send_date)
      formData.append('arrival_date', postData.arrival_date)
      formData.append('id_com_customer', postData.id_com_customer)
      formData.append('id_com_cars', postData.id_com_cars)
      formData.append('id_com_damage_repair_company', postData.id_com_damage_repair_company)
      formData.append('id_com_damage_repair_status', postData.id_com_damage_repair_status)
      formData.append('id_com_damage_tracking', postData.id_com_damage_tracking)
      formData.append('id_com_user', postData.id_com_user)
      if (postData.upload_file) {
        formData.append('upload_file', postData.upload_file)
        formData.append('id_com_damage_tracking_document_types', postData.id_com_damage_tracking_document_types)
      }
      return axiosIns
        .post('/data/damage_car_repair/saveData', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/damage_car_repair/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
