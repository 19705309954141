import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    filterData: {
      sdate: null,
      edate: null,
      id_com_survey_type: null,
    },
    istatistics: {
      total: 0,
      completed: 0,
      avarage: 0,
      high_low: 0,
      low: 0,
      natural: 0,
      good: 0,
      very_good: 0,
    },
    dataLoading: false,
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    getDataCount(state) {
      return state.dataCount
    },
    getIstatistics(state) {
      return state.istatistics
    },
    getFilterData(state) {
      return state.filterData
    },
    getDataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_ISTATISTICS(state, data) {
      state.istatistics = data
    },
    RESET_DATA_LIST(state) {
      state.dataList = []
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        sdate: null,
        edate: null,
        id_com_survey_type: null,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    dataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/surveys/surveysReport', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
          commit('SET_ISTATISTICS', response.data.istatistics)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    resetDataList({ commit }) {
      commit('RESET_DATA_LIST')
    },
    resetFilterData({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}
