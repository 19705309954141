import axiosIns from '@/libs/axios'

const ROOT_URL = '/core/cropper_upload'
export default {
  namespaced: true,
  state: {
    uploadImage: null,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataSaveStatus(state) {
      return state.dataSave
    },
    uploading(state) {
      return state.uploading
    },
  },
  mutations: {
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_UPLOAD_IMAGE(state, data) {
      state.uploadImage = data
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
  },
  actions: {
    uploadImage({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('folder', postData.folder)
      if (postData.resize) {
        formData.append('resize', postData.resize)
      }
      if (postData.width) {
        formData.append('width', postData.width)
      }
      if (postData.thumb) {
        formData.append('thumb', postData.thumb)
      }
      formData.append('upload_image', postData.upload_image, postData.filename)
      return axiosIns
        .post(ROOT_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_UPLOAD_IMAGE', response.data.image)
          commit('SET_UPLOADING', false)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
