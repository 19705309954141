import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataItem: {
      id: null,
      title: null,
      subtitle: null,
      file: null,
      submitStatus: false,
    },
    dataSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
    fileData: {
      file: null,
      submitStatus: null,
      uploading: false,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    fileData(state) {
      return state.fileData
    },
    dataSaveStatus(state) {
      return state.dataSaveStatus
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.dataItem = data
    },
    SAVE_DATA(state, data) {
      state.dataSaveStatus = data
    },
    SET_UPLOADING(state, data) {
      state.fileData.uploading = data
    },
    RESET_UPLOAD_DATA(state) {
      state.fileData = {
        file: null,
        submitStatus: null,
        uploading: false,
      }
    },
  },
  actions: {
    getData({ commit }) {
      axiosIns
        .post('/portal/organization_charts')
        .then(response => {
          commit('SET_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      axiosIns
        .post('/portal/organization_charts/saveData', postData)
        .then(response => {
          commit('SAVE_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    uploadFile({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post('/portal/organization_charts/uploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SAVE_DATA', response.data)
          commit('SET_UPLOADING', false)
          commit('RESET_UPLOAD_DATA')
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
