import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    dataItem: {
      id: null,
      value: null,
      id_com_cars: null,
      id_com_customer: null,
      id_com_service_tracing: null,
      id_com_user: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    getDataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    getDataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    DATA_LIST(state, data) {
      state.dataList = data
    },
    DATA_ITEM(state, data) {
      state.dataItem = data
    },
    DATA_COUNT(state, data) {
      state.dataCount = data
    },
    DATA_SAVE(state, data) {
      state.dataSave = data
    },
    DATA_RESET(state) {
      state.dataItem = {
        id: null,
        value: null,
        id_com_cars: null,
        id_com_customer: null,
        id_com_service_tracing: null,
        id_com_user: null,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/car_battery_measurement', data)
        .then(response => {
          commit('DATA_LIST', response.data.data)
          commit('DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_car_battery_measurement.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/car_battery_measurement', data)
        .then(response => {
          commit('DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    dataSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/car_battery_measurement/saveData', data)
        .then(response => {
          commit('DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    dataReset({ commit }) {
      commit('DATA_RESET', { status: true })
    },
  },
}
