import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataItem: {
      id_com_interview_subject: null,
      id_com_user: null,
      id_com_interview_status: null,
      id_com_customer: null,
      idate: moment().format('YYYY-MM-DD'),
      itime: moment().format('HH:MM'),
      content: 'Medusa Otomatik Görüşme Kapatma',
      chasis: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
  },
  actions: {
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/Insurance/interview_batch/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
  },
}
