export default [
  {
    path: '/sale_orderforms',
    name: 'SaleOrderForms',
    component: () => import('@/views/Sales/Sale_orderforms/Index.vue'),
    meta: {
      pageTitle: 'Satış Föyleri',
      breadcrumb: [
        {
          text: 'Satış Föyleri',
          active: true,
        },
      ],
      resource: 'SALE_ORDER_FORMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/cancel-sale-pendings',
    name: 'PendingCancelSale',
    component: () => import('@/views/Sales/Sale_orderforms/CancelSales.vue'),
    meta: {
      pageTitle: 'Satış Föyleri',
      breadcrumb: [
        {
          text: 'İptal İstekleri',
          active: true,
        },
      ],
      resource: 'PENDING_CANCEL_SALE_LIST',
      action: 'read',
    },
  },
  {
    path: '/cancel-sale-pendings/:id',
    name: 'PendingCancelSale',
    component: () => import('@/views/Sales/Sale_orderforms/CancelSales.vue'),
    meta: {
      pageTitle: 'Satış Föyleri',
      breadcrumb: [
        {
          text: 'İptal Bekleyenler',
          active: true,
        },
      ],
      resource: 'PENDING_CANCEL_SALE_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_orderforms/add/:order_number',
    name: 'SaleOrderFormsAdd',
    component: () => import('@/views/Sales/Sale_orderforms/Add.vue'),
    meta: {
      pageTitle: 'Satış Föyleri',
      breadcrumb: [
        {
          text: 'Satış Föyleri',
          to: '/sale_orderforms',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SALE_ORDER_FORMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_orderforms/view/:order_number',
    name: 'SaleOrderFormsView',
    component: () => import('@/views/Sales/Sale_orderforms/View.vue'),
    meta: {
      pageTitle: 'Satış Föyleri',
      breadcrumb: [
        {
          text: 'Satış Föyleri',
          to: '/sale_orderforms',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SALE_ORDER_FORMS_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_goals',
    name: 'SaleGoals',
    component: () => import('@/views/Sales/Sale_goals/Index.vue'),
    meta: {
      pageTitle: 'GPR Kapanış Verisi',
      breadcrumb: [
        {
          text: 'GPR Kapanış Verisi',
          active: true,
        },
      ],
      resource: 'SALE_GOALS_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_goals/add',
    name: 'SaleGoalsAdd',
    component: () => import('@/views/Sales/Sale_goals/Add.vue'),
    meta: {
      pageTitle: 'GPR Kapanış Verisi',
      breadcrumb: [
        {
          text: 'GPR Kapanış Verisi',
          to: '/sale_goals',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SALE_GOALS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sale_goals/edit/:id',
    name: 'SaleGoalsEdit',
    component: () => import('@/views/Sales/Sale_goals/Edit.vue'),
    meta: {
      pageTitle: 'GPR Kapanış Verisi',
      breadcrumb: [
        {
          text: 'GPR Kapanış Verisi',
          to: '/sale_goals',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SALE_GOALS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/consultant_goals',
    name: 'ConsultantGoals',
    component: () => import('@/views/Sales/Consultant_goals/Index.vue'),
    meta: {
      pageTitle: 'Hedefler',
      breadcrumb: [
        {
          text: 'Hedefler',
          active: true,
        },
      ],
      resource: 'CONSULTANT_GOALS_LIST',
      action: 'read',
    },
  },
  {
    path: '/consultant_goals/add',
    name: 'ConsultantGoalsAdd',
    component: () => import('@/views/Sales/Consultant_goals/Add.vue'),
    meta: {
      pageTitle: 'Hedefler',
      breadcrumb: [
        {
          text: 'Hedefler',
          to: '/consultant_goals',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CONSULTANT_GOALS_ADD',
      action: 'read',
    },
  },
  {
    path: '/consultant_goals/edit/:id',
    name: 'ConsultantGoalsEdit',
    component: () => import('@/views/Sales/Consultant_goals/Edit.vue'),
    meta: {
      pageTitle: 'Hedefler',
      breadcrumb: [
        {
          text: 'Hedefler',
          to: '/consultant_goals',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CONSULTANT_GOALS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/consultant_goals/pending',
    name: 'ConsultantGoalPendings',
    component: () => import('@/views/Sales/Consultant_goals/UpdatePending.vue'),
    meta: {
      pageTitle: 'Hedefler',
      breadcrumb: [
        {
          to: '/consultant_goals',
          text: 'Hedefler',
          active: false,
        },
        {
          text: 'Güncelleme Onayı Bekleyenler',
          active: true,
        },
      ],
      resource: 'CONSULTANT_GOAL_PENDINGS',
      action: 'read',
    },
  },
  {
    path: '/sale_deliveries',
    name: 'SaleDeliveries',
    component: () => import('@/views/Sales/Sale_delivery/Pending/Index.vue'),
    meta: {
      pageTitle: 'Teslimat Bekleyen Araçlar',
      breadcrumb: [
        {
          text: 'Teslimat Bekleyen Araçlar',
          active: true,
        },
      ],
      resource: 'SALE_DELIVERIES_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_deliveries/add/:id',
    name: 'SaleDeliveriesAdd',
    component: () => import('@/views/Sales/Sale_delivery/Pending/Add.vue'),
    meta: {
      pageTitle: 'Teslimat Bekleyen Araçlar',
      breadcrumb: [
        {
          text: 'Teslimat Bekleyen Araçlar',
          to: '/sale_deliveries',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SALE_DELIVERIES_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_deliveries/completed',
    name: 'SaleDeliveriesCompleted',
    component: () => import('@/views/Sales/Sale_delivery/Completed/Index.vue'),
    meta: {
      pageTitle: 'Teslim Edilen Araçlar',
      breadcrumb: [
        {
          text: 'Teslim Edilen Araçlar',
          active: true,
        },
      ],
      resource: 'COMPLETED_SALE_DELIVERIES_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale_deliveries/completed/view/:id',
    name: 'SaleDeliveriesCompletedView',
    component: () => import('@/views/Sales/Sale_delivery/Completed/View.vue'),
    meta: {
      pageTitle: 'Teslim Edilen Araçlar',
      breadcrumb: [
        {
          text: 'Teslim Edilen Araçlar',
          to: '/sale_deliveries/completed',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'COMPLETED_SALE_DELIVERY_VIEW',
      action: 'read',
    },
  },
  {
    path: '/stocks',
    name: 'SaleStocks',
    component: () => import('@/views/Sales/Stocks/Index.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_LIST',
      action: 'read',
    },
  },
  {
    path: '/stocks/allocate-add',
    name: 'AllocateSaleStocks',
    component: () => import('@/views/Sales/Stocks/AllocateStocks/Index.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Tahsis Stok Ekle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_LIST',
      action: 'read',
    },
  },
  {
    path: '/stocks/add',
    name: 'SaleStocksAdd',
    component: () => import('@/views/Sales/Stocks/Add.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/stocks',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_ADD',
      action: 'read',
    },
  },
  {
    path: '/stocks/add/:chasis',
    name: 'SaleStocksAddPage',
    component: () => import('@/views/Sales/Stocks/AddStock.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/stocks',
          active: false,
        },
        {
          text: 'Stok Ekle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_LIST',
      action: 'read',
    },
  },
  {
    path: '/stocks/batch-add',
    name: 'SaleStocksAddBatchPage',
    component: () => import('@/views/Sales/Stocks/AddBatchStock.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/stocks',
          active: false,
        },
        {
          text: 'Toplu Stok Ekle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_LIST',
      action: 'read',
    },
  },
  {
    path: '/stocks/edit/:id',
    name: 'SaleStocksEditPage',
    component: () => import('@/views/Sales/Stocks/Edit.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/stocks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_LIST',
      action: 'read',
    },
  },
  {
    path: '/stocks/custom-stock',
    name: 'SaleStocksCustomStock',
    component: () => import('@/views/Sales/Stocks/CustomStock.vue'),
    meta: {
      pageTitle: 'Stoklar',
      breadcrumb: [
        {
          text: 'Stoklar',
          to: '/stocks',
          active: false,
        },
        {
          text: 'Özel Stok Ekle',
          active: true,
        },
      ],
      resource: 'SALE_STOCK_CUSTOM',
      action: 'read',
    },
  },
  {
    path: '/sale/documents',
    name: 'SaleDocuments',
    component: () => import('@/views/Sales/Sale_documents/Index.vue'),
    meta: {
      pageTitle: 'Satış Evrakları',
      breadcrumb: [
        {
          text: 'Evraklar',
          active: true,
        },
      ],
      resource: 'SALE_DOCUMENTS',
      action: 'read',
    },
  },
  {
    path: '/sale/documents/view/:id',
    name: 'SaleDocumentView',
    component: () => import('@/views/Sales/Sale_documents/View.vue'),
    meta: {
      pageTitle: 'Satış Evrakları',
      breadcrumb: [
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SALE_DOCUMENTS',
      action: 'read',
    },
  },
]
