export default [
  {
    path: '/sparepart-stock-groups',
    name: 'SparepartStockGroups',
    component: () => import('@/views/Defines/SparepartStockGroups/Index.vue'),
    meta: {
      pageTitle: 'Stok Grupları',
      breadcrumb: [
        {
          text: 'Stok Grupları',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_GROUPS',
      action: 'read',
    },
  },
  {
    path: '/sparepart-stock-groups/add',
    name: 'SparepartStockGroupsAdd',
    component: () => import('@/views/Defines/SparepartStockGroups/Add.vue'),
    meta: {
      pageTitle: 'Stok Grubu Ekle',
      breadcrumb: [
        {
          text: 'Stok Grupları',
          to: '/sparepart-stock-groups',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_GROUPS_ADD',
      action: 'read',
    },
  },
  {
    path: '/sparepart-stock-groups/edit/:id',
    name: 'SparepartStockGroupsEdit',
    component: () => import('@/views/Defines/SparepartStockGroups/Edit.vue'),
    meta: {
      pageTitle: 'Stok Grubu Güncelle',
      breadcrumb: [
        {
          text: 'Stok Grupları',
          to: '/sparepart-stock-groups',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SPAREPART_STOCK_GROUPS_EDIT',
      action: 'read',
    },
  },
]
