import moment from 'moment'
import axiosIns from '@/libs/axios'

const ROOT_URL = '/data/service/service_work_orders'
export default {
  namespaced: true,
  state: {
    dataList: [],
    openDataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      wdate: moment().format('YYYY-MM-DD'),
      work_no: null,
      target_min: null,
      is_da: null,
      is_repair: null,
      is_change: null,
      is_work: null,
      id_com_service_sections: null,
      id_com_service_workers: null,
      id_com_service_works: null,
      lines: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    filterParams: {
      work_no: null,
      id_com_location: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    openDataList(state) {
      return state.openDataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    filterParams(state) {
      return state.filterParams
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_OPEN_DATA_LIST(state, data) {
      state.openDataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        wdate: moment().format('YYYY-MM-DD'),
        work_no: null,
        target_min: null,
        is_da: null,
        is_repair: null,
        is_change: null,
        is_work: null,
        id_com_service_sections: null,
        id_com_service_workers: null,
        id_com_service_works: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getOpenDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/open`, data)
        .then(response => {
          commit('SET_OPEN_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_service_work_orders.id': id,
        },
        array: false,
        lines: true,
        duration: true,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
