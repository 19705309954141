export default [
  {
    path: '/marketing/mobile-app/sales-blog',
    name: 'MobileAppSaleDiscover',
    component: () => import('@/views/Marketing/Mobile_app/Sale_discover.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Mobil Uygulama',
          active: false,
        },
        {
          text: 'Sıfır Satış Blog',
          active: true,
        },
      ],
      resource: 'MARKETING_MOBILE_APP_SALE_DISCOVER',
      action: 'read',
    },
  },
  {
    path: '/marketing/mobile-app/sales-blog/add',
    name: 'MobileAppSaleDiscover',
    component: () => import('@/views/Marketing/Mobile_app/Sale_discover/Add.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Mobil Uygulama',
          active: false,
        },
        {
          to: '/marketing/mobile-app/sales-blog',
          text: 'Sıfır Satış Blog',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_MOBILE_APP_SALE_DISCOVER_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/mobile-app/sales-blog/edit/:id',
    name: 'MobileAppSaleDiscover',
    component: () => import('@/views/Marketing/Mobile_app/Sale_discover/Edit.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Mobil Uygulama',
          active: false,
        },
        {
          to: '/marketing/mobile-app/sales-blog',
          text: 'Sıfır Satış Blog',
          active: false,
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'MARKETING_MOBILE_APP_SALE_DISCOVER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/marketing/mobile-app/sales-blog/add-line/:id',
    name: 'MobileAppSaleDiscover',
    component: () => import('@/views/Marketing/Mobile_app/Sale_discover/AddLine.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Mobil Uygulama',
          active: false,
        },
        {
          to: '/marketing/mobile-app/sales-blog',
          text: 'Sıfır Satış Blog',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'MARKETING_MOBILE_APP_SALE_DISCOVER_ADD',
      action: 'read',
    },
  },
  {
    path: '/marketing/mobile-app/sales-blog/edit-line/:id',
    name: 'MobileAppSaleDiscover',
    component: () => import('@/views/Marketing/Mobile_app/Sale_discover/EditLine.vue'),
    meta: {
      pageTitle: 'Pazarlama',
      breadcrumb: [
        {
          text: 'Mobil Uygulama',
          active: false,
        },
        {
          to: '/marketing/mobile-app/sales-blog',
          text: 'Sıfır Satış Blog',
          active: false,
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'MARKETING_MOBILE_APP_SALE_DISCOVER_ADD',
      action: 'read',
    },
  },
]
