export default [
  {
    path: '/reports/insurance/production',
    name: 'ReportsInsuranceProduction',
    component: () => import('@/views/Reports/Insurance/Production.vue'),
    meta: {
      pageTitle: 'Sigorta Raporları',
      breadcrumb: [
        {
          text: 'Üretim',
          active: true,
        },
      ],
      resource: 'REPORTS_INSURANCE_PRODUCTIONS',
      action: 'read',
    },
  },
  {
    path: '/reports/insurance/renewals',
    name: 'ReportsInsuranceRenewals',
    component: () => import('@/views/Reports/Insurance/Renewals.vue'),
    meta: {
      pageTitle: 'Sigorta Raporları',
      breadcrumb: [
        {
          text: 'Yenileme Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_INSURANCE_RENEWALS',
      action: 'read',
    },
  },
  {
    path: '/reports/insurance/reminders',
    name: 'ReportsInsuranceReminders',
    component: () => import('@/views/Reports/Insurance/Reminders.vue'),
    meta: {
      pageTitle: 'Sigorta Raporları',
      breadcrumb: [
        {
          text: 'Müşteri Aramaları Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_INSURANCE_REMINDERS',
      action: 'read',
    },
  },
  {
    path: '/reports/insurance/sales',
    name: 'ReportsInsuranceSales',
    component: () => import('@/views/Reports/Insurance/Sales.vue'),
    meta: {
      pageTitle: 'Sigorta Raporları',
      breadcrumb: [
        {
          text: 'Sıfır Satış Raporu',
          active: true,
        },
      ],
      resource: 'REPORTS_INSURANCE_SALES',
      action: 'read',
    },
  },
]
