export default [
  {
    path: '/damage-tracking-status',
    name: 'DamageTrackingStatus',
    component: () => import('@/views/Defines/Damage_tracking_status/Index.vue'),
    meta: {
      pageTitle: 'Hasar Takibi Durum Tipleri',
      breadcrumb: [
        {
          text: 'Hasar Takibi Durum Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_STATUS',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking-status/add',
    name: 'DamageTrackingStatusAdd',
    component: () => import('@/views/Defines/Damage_tracking_status/Add.vue'),
    meta: {
      pageTitle: 'Hasar Takibi Durum Tipi Ekle',
      breadcrumb: [
        {
          text: 'Hasar Takibi Durum Tipleri',
          to: '/damage-tracking-status',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_STATUS',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking-status/edit/:id',
    name: 'DamageTrackingStatusEdit',
    component: () => import('@/views/Defines/Damage_tracking_status/Edit.vue'),
    meta: {
      pageTitle: 'Hasar Takibi Durum Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Hasar Takibi Durum Tipleri',
          to: '/damage-tracking-status',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_STATUS',
      action: 'read',
    },
  },
]
