import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    controlStatus: {
      data: null,
      message: null,
      status: null,
      customer: {},
    },
    dataSave: {
      message: null,
      status: null,
    },
    dataLoading: false,
  },
  getters: {
    controlStatus(state) {
      return state.controlStatus
    },
    marketingSmsSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
  },
  mutations: {
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_CONTROL_DATA(state, data) {
      state.controlStatus = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    RESET_CONTROL(state) {
      state.controlStatus = {
        data: null,
        message: null,
        status: null,
        customer: {},
      }
    },
    RESET_SAVESMS(state) {
      state.dataSave = {
        message: null,
        status: null,
      }
    },
  },
  actions: {
    customerControl({ commit }, id) {
      axiosIns
        .post('/SmsSend/Mobile_app/control', { id_com_customer: id })
        .then(response => {
          commit('SET_CONTROL_DATA', response.data)
        })
        .catch(error => console.error(error))
    },
    sendSms({ commit }, id) {
      commit('SET_DATA_LOADING', true)
      axiosIns
        .post('/SmsSend/Mobile_app/send', { id_com_customer: id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
  },
}
