export default [
  {
    path: '/webforms/i2buyform',
    name: 'I2buyform',
    component: () => import('@/views/Webforms/I2buyform/Index.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Almak İstiyorum',
          active: true,
        },
      ],
      resource: 'WEBFORM_I2BUYFORM',
      action: 'read',
    },
  },
  {
    path: '/webforms/i2buyform/view/:id',
    name: 'I2buyformView',
    component: () => import('@/views/Webforms/I2buyform/View.vue'),
    meta: {
      pageTitle: 'Web Talepleri',
      breadcrumb: [
        {
          text: 'İnallar2 Almak İstiyorum',
          to: '/webforms/i2buyform',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'WEBFORM_I2BUYFORM',
      action: 'read',
    },
  },
]
