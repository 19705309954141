import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    genders: [],
    gendersCount: 0,
    gender: {},
    genderSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getGenders(state) {
      return state.genders
    },
    getGendersCount(state) {
      return state.gendersCount
    },
    getGender(state) {
      return state.gender
    },
    getGenderSaveStatus(state) {
      return state.genderSave
    },
  },
  mutations: {
    GENDERS_LIST(state, data) {
      state.genders = data
    },
    GENDER_VIEW(state, data) {
      state.gender = data
    },
    GENDERS_COUNT(state, data) {
      state.gendersCount = data
    },
    GENDER_SAVE(state, data) {
      state.genderSave = data
    },
  },
  actions: {
    gendersList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/genders', data)
        .then(response => {
          commit('GENDERS_LIST', response.data.data)
          commit('GENDERS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    genderView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_gender.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/genders', data)
        .then(response => {
          commit('GENDER_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    genderSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/defines/genders/saveData', data)
        .then(response => {
          commit('GENDER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    genderDelete({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/defines/genders/deleteData', data)
        .then(response => {
          commit('GENDER_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
