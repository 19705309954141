export default [
  {
    path: '/crm',
    name: 'CrmAll',
    component: () => import('@/views/Crm/Index.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Tüm Başvurular',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/crm/pending',
    name: 'CrmPending',
    component: () => import('@/views/Crm/Pending.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Bekleyen Başvurular',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/crm/tracking',
    name: 'CrmTracking',
    component: () => import('@/views/Crm/Tracking.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Takip Edilen Başvurular',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/crm/approval',
    name: 'CrmApproval',
    component: () => import('@/views/Crm/Approval.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Onay Bekleyen Başvurular',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_LIST',
      action: 'read',
    },
  },
  {
    path: '/crm/search',
    name: 'CrmSearch',
    component: () => import('@/views/Crm/Search.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Başvurular',
          to: '/crm',
          active: false,
        },
        {
          text: 'Müşteri Ara',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/crm/add/:id',
    name: 'CrmAdd',
    component: () => import('@/views/Crm/Add.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Başvurular',
          to: '/crm',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_ADD',
      action: 'read',
    },
  },
  {
    path: '/crm/edit/:id',
    name: 'CrmEdit',
    component: () => import('@/views/Crm/Edit.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Başvurular',
          to: '/crm',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/crm/view/:id',
    name: 'CrmView',
    component: () => import('@/views/Crm/View.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Başvurular',
          to: '/crm',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CRM_REQUESTS_VIEW',
      action: 'read',
    },
  },
  {
    path: '/i2surveys',
    name: 'i2Surveys',
    component: () => import('@/views/I2Surveys/Index.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: '2.El Anketler',
          active: true,
        },
      ],
      resource: 'CRM_I2_SURVEYS_LIST',
      action: 'read',
    },
  },
  {
    path: '/sale-surveys',
    name: 'SaleSurveys',
    component: () => import('@/views/SaleSurveys/Index.vue'),
    meta: {
      pageTitle: 'Müşteri İlişkileri',
      breadcrumb: [
        {
          text: 'Sıfır Satış Anketler',
          active: true,
        },
      ],
      resource: 'CRM_SALE_SURVEYS_LIST',
      action: 'read',
    },
  },
  {
    path: '/contact-assigments',
    name: 'ContactAssigments',
    component: () => import('@/views/Crm/ContactAssignments.vue'),
    meta: {
      pageTitle: 'Web İletişim Atamaları',
      breadcrumb: [
        {
          text: 'Web İletişim Atamaları',
          active: true,
        },
      ],
      resource: 'CRM_CONTACT_ASSIGMENT',
      action: 'read',
    },
  },
  {
    path: '/map-scores',
    name: 'MapScores',
    component: () => import('@/views/MapScores/Index.vue'),
    meta: {
      pageTitle: 'Harita Puan Verileri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CRM_MAP_SCORES',
      action: 'read',
    },
  },
  {
    path: '/map-scores/add',
    name: 'MapScores',
    component: () => import('@/views/MapScores/Add.vue'),
    meta: {
      pageTitle: 'Harita Puan Verileri',
      breadcrumb: [
        {
          to: '/map-scores',
          text: 'Liste',
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'CRM_MAP_SCORES_ADD',
      action: 'read',
    },
  },
  {
    path: '/map-scores/edit/:id',
    name: 'MapScores',
    component: () => import('@/views/MapScores/Edit.vue'),
    meta: {
      pageTitle: 'Harita Puan Verileri',
      breadcrumb: [
        {
          to: '/map-scores',
          text: 'Liste',
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'CRM_MAP_SCORES_EDIT',
      action: 'read',
    },
  },
]
