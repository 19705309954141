import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: null,
    filterData: {
      sdate: null,
      edate: null,
      sms_type: null,
    },
    dataLoading: false,
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    getFilterData(state) {
      return state.filterData
    },
    getDataLoading(state) {
      return state.dataLoading
    },
    dataCount(state) {
      return state.dataCount
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    RESET_FILTER_DATA(state, data) {
      state.filterData = {
        sdate: null,
        edate: null,
        sms_type: null,
      }
    },
  },
  actions: {
    dataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/marketing/sms_report', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    resetFilterData({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}
