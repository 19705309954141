import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    i2Survey: {
      totals: {},
      statuses: [],
      users: [],
      detail: [],
    },
  },
  getters: {
    getI2SurveyReport(state) {
      return state.i2Survey
    },
  },
  mutations: {
    I2_SURVEY_REPORT(state, data) {
      state.i2Survey = data
    },
    RESET_I2_SURVEY_REPORT(state) {
      state.i2Survey = {
        totals: {},
        statuses: [],
        users: [],
        detail: [],
      }
    },
  },
  actions: {
    i2SurveyReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/surveys/inallar2', data)
        .then(response => {
          commit('I2_SURVEY_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
    i2SurveyReset({ commit }) {
      commit('RESET_I2_SURVEY_REPORT')
    },
  },
}
