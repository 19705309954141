import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    saleOrderForms: [],
    customersList: [],
    saleOrderFormsCount: null,
    saleOrderForm: {
      id: null,
      order_number: null,
      interview_number: null,
      accessories: null,
      payments: {
        id_com_payment: [],
        pprice: [],
        pdate: [],
        pcontent: [],
      },
      invoice: null,
      delivery: null,
      delivery_form: null,
      proforma_form: null,
      licence_plate: null,
      sale_price: null,
      banderole_price: null,
      plate_price: null,
      delivery_price: null,
      sale_type: null,
      status: null,
      lock_status: null,
      step: null,
      ddate: null,
      notes: null,
      fleet_cars: null,
      informationmail: null,
      licence_plate1: null,
      licence_plate_group: null,
      bank_pledge: null,
      pool_status: null,
      id_com_brand: null,
      id_com_customer: null,
      id_com_customer1: null,
      id_com_stock: null,
      id_com_appropriation: null,
      id_com_swapcar: null,
      id_com_otvrate: null,
      id_com_kdvrate: null,
      id_com_meet: null,
      id_com_user: null,
      adddate: null,
      addtime: null,
      supportInvoices: {
        lines: [],
        total: null,
      },
      customerAccountCodes: {
        account_code: null,
        user_section: null,
      },
      swapcarSaleContract: null,
      submitStatus: false,
    },
    quickSearch: {
      keyword: '',
    },
    saleOrderFormSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: true,
    candeniz: {},
    deliveryForm: {},
    proformaForm: {},
    filterData: {
      id_com_location: null,
      id_com_brand: null,
      id_com_user: null,
      status: null,
      sdate: null,
      edate: null,
    },
  },
  getters: {
    getSaleOrderForms(state) {
      return state.saleOrderForms
    },
    getCustomersList(state) {
      return state.customersList
    },
    getSaleOrderFormsCount(state) {
      return state.saleOrderFormsCount
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getSaleOrderForm(state) {
      return state.saleOrderForm
    },
    getSaleOrderFormSaveStatus(state) {
      return state.saleOrderFormSave
    },
    getSaleOrderFormLoading(state) {
      return state.dataLoading
    },
    getCandeniz(state) {
      return state.candeniz
    },
    deliveryForm(state) {
      return state.deliveryForm
    },
    proformaForm(state) {
      return state.proformaForm
    },
    getFilterData(state) {
      return state.filterData
    },
  },
  mutations: {
    SALE_ORDER_FORMS_LIST(state, data) {
      state.saleOrderForms = data
    },
    CUSTOMERS_LIST(state, data) {
      state.customersList = data
    },
    SALE_ORDER_FORM_VIEW(state, data) {
      state.saleOrderForm = data
    },
    SALE_ORDER_FORMS_COUNT(state, data) {
      state.saleOrderFormsCount = data
    },
    SALE_ORDER_FORM_SAVE(state, data) {
      state.saleOrderFormSave = data
    },
    SALE_ORDER_FORM_RESET(state, data) {
      if (data.status) {
        state.saleOrderForm = {
          id: null,
          order_number: null,
          interview_number: null,
          accessories: null,
          payments: {
            id_com_payment: [],
            pprice: [],
            pdate: [],
            pcontent: [],
          },
          invoice: null,
          delivery: null,
          delivery_form: null,
          proforma_form: null,
          licence_plate: null,
          sale_price: null,
          banderole_price: null,
          plate_price: null,
          delivery_price: null,
          sale_type: null,
          status: null,
          lock_status: null,
          step: null,
          ddate: null,
          notes: null,
          fleet_cars: null,
          informationmail: null,
          licence_plate1: null,
          licence_plate_group: null,
          bank_pledge: null,
          pool_status: null,
          id_com_brand: null,
          id_com_customer: null,
          id_com_customer1: null,
          id_com_stock: null,
          id_com_appropriation: null,
          id_com_swapcar: null,
          id_com_otvrate: null,
          id_com_kdvrate: null,
          id_com_meet: null,
          id_com_user: null,
          adddate: null,
          addtime: null,
          swapcarSaleContract: null,
          supportInvoices: {
            lines: [],
            total: null,
          },
          customerAccountCodes: {
            account_code: null,
            user_section: null,
          },
          submitStatus: false,
        }
      }
    },
    SET_CANDENIZ(state, data) {
      state.candeniz = data
    },
    SET_DELIVERY_FORM(state, data) {
      state.deliveryForm = data
    },
    SET_PROFORMA_FORM(state, data) {
      state.proformaForm = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    saleOrderFormsList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/sale_orderforms', data)
        .then(response => {
          commit('SALE_ORDER_FORMS_LIST', response.data.data)
          commit('SALE_ORDER_FORMS_COUNT', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    saleOrderFormsView({ commit }, orderNumber) {
      const data = JSON.stringify({
        where: {
          'com_sale_orderform.order_number': orderNumber,
        },
        array: false,
      })
      return axiosIns
        .post('/data/sale_orderforms', data)
        .then(response => {
          commit('SALE_ORDER_FORM_VIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saleOrderFormsCancel({ commit }, data) {
      return axiosIns
        .post('/data/sale_orderforms/cancelForm', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saleOrderFormsSendApprove({ commit }, data) {
      return axiosIns
        .post('/data/sale_orderforms/sendApprove', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    candenizData({ commit }, orderNumber) {
      axiosIns
        .post(`/data/sale_orderforms/candeniz/${orderNumber}`)
        .then(response => {
          commit('SET_CANDENIZ', response.data)
        })
        .catch(error => console.error(error))
    },
    getDeliveryForm({ commit }, id) {
      return axiosIns
        .post('/data/sale_orderforms/deliveryForm', { id })
        .then(response => {
          commit('SET_DELIVERY_FORM', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    getProformaForm({ commit }, id) {
      return axiosIns
        .post('/data/sale_orderforms/proformaForm', { id })
        .then(response => {
          commit('SET_PROFORMA_FORM', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/sale_orderforms/saveData', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    managerSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/sale_orderforms/managerSave', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveDeliveryForm({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/sale_orderforms/saveDeliveryForm', data)
        .then(response => response.data)
        .catch(error => console.error(error))
    },
    saveProformaForm({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/sale_orderforms/saveProformaForm', data)
        .then(response => response.data)
        .catch(error => console.error(error))
    },
    submitManager({ commit }, id) {
      const data = JSON.stringify({ id })
      return axiosIns
        .post('/data/sale_orderforms/submitManager', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    sendCandeniz({ commit }, id) {
      const data = JSON.stringify({ id })
      return axiosIns
        .post('/data/sale_orderforms/sendCandeniz', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveDocument({ commit }, postData) {
      const formData = new FormData()
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in postData) {
        formData.append(key, postData[key])
      }
      axiosIns
        .post('/data/sale_orderforms/saveDocument', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    deleteDocument({ commit }, filename) {
      const data = JSON.stringify({ filename })
      return axiosIns
        .post('/data/sale_orderforms/deleteDocument', data)
        .then(response => {
          commit('SALE_ORDER_FORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
