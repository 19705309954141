import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      score: null,
      date: null,
      id_com_location: null,
      id_com_brand: null,
      id_com_user: null,
      submitStatus: false,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: false,
    selectedRooms: [],
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
    selectedRooms(state) {
      return state.selectedRooms
    },
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedRooms = val
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    SET_RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        score: null,
        date: null,
        id_com_location: null,
        id_com_brand: null,
        id_com_user: null,
        submitStatus: false,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/meeting_calendars', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data)
          // commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
          return response
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_meeting_calendars.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/meeting_calendars', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/meeting_calendars/saveData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    deleteData({ commit }, id) {
      return axiosIns
        .post('/data/meeting_calendars/deleteData', { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
