export default [
  {
    path: '/battery-offers',
    name: 'BatteryOffers',
    component: () => import('@/views/BatteryOffers/Index.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_LIST',
      action: 'read',
    },
  },
  {
    path: '/battery-offers/customer-search',
    name: 'BatteryOfferCustomerSearch',
    component: () => import('@/views/BatteryOffers/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          to: '/battery-offers',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/battery-offers/add-service/:id_customer/:id_com_service_tracing/:id_com_cars',
    name: 'BatteryOfferAdd',
    component: () => import('@/views/BatteryOffers/Add.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          to: '/battery-offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/battery-offers/add/:id_customer/:id_com_cars',
    name: 'BatteryOfferAdd',
    component: () => import('@/views/BatteryOffers/Add.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          to: '/battery-offers',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_ADD',
      action: 'read',
    },
  },
  {
    path: '/battery-offers/edit/:id',
    name: 'BatteryOfferEdit',
    component: () => import('@/views/BatteryOffers/Edit.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          to: '/battery-offers',
          active: false,
        },
        {
          text: 'Düzenle',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_EDIT',
      action: 'read',
    },
  },
  {
    path: '/battery-offers/view/:id',
    name: 'BatteryOfferEdit',
    component: () => import('@/views/BatteryOffers/View.vue'),
    meta: {
      pageTitle: 'Akü Teklifleri',
      breadcrumb: [
        {
          text: 'Tüm Akü Teklifleri',
          to: '/battery-offers',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'BATTERY_OFFER_VIEW',
      action: 'read',
    },
  },
]
