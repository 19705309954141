import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    ItemList: [],
    ItemCount: 0,
    ItemData: {
      id: null,
      title: null,
      content: null,
      image: null,
      thumb: null,
      id_com_brand: null,
      id_com_user: null,
      items: [],
      submitStatus: false,
    },
    ItemDataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getItemList(state) {
      return state.ItemList
    },
    getItemCount(state) {
      return state.ItemCount
    },
    getItemData(state) {
      return state.ItemData
    },
    getItemDataSaveStatus(state) {
      return state.ItemDataSave
    },
  },
  mutations: {
    ITEM_DATA_LIST(state, data) {
      state.ItemList = data
    },
    ITEM_DATA(state, data) {
      state.ItemData = data
    },
    ITEM_DATA_COUNT(state, data) {
      state.ItemCount = data
    },
    ITEM_DATA_SAVE(state, data) {
      state.ItemDataSave = data
    },
    RESET_ITEM_DATA_LIST(state) {
      state.ItemList = []
    },
    RESET_ITEM_DATA(state) {
      state.ItemData = {
        id: null,
        title: null,
        content: null,
        image: null,
        thumb: null,
        id_com_brand: null,
        id_com_user: null,
        items: [],
        submitStatus: false,
      }
    },
  },
  actions: {
    itemDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/marketing/MobileApp/sale_discover', data)
        .then(response => {
          commit('ITEM_DATA_LIST', response.data.data)
          commit('ITEM_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    itemData({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'blog_app.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/marketing/MobileApp/sale_discover', data)
        .then(response => {
          commit('ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/marketing/MobileApp/sale_discover/saveData', data)
        .then(response => {
          commit('ITEM_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    deleteData({ commit }, id) {
      return axiosIns
        .post('/marketing/MobileApp/sale_discover/deleteData', { id })
        .then(response => {
          commit('ITEM_DATA_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}
