import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import app from '@/router/routes/app'
import swapBrands from '@/router/routes/defines/swapbrands'
import swapModels from '@/router/routes/defines/swapmodels'
import brands from '@/router/routes/defines/brands'
import banks from '@/router/routes/defines/banks'
import tireBrands from '@/router/routes/defines/tire_brands'
import tireShelfs from '@/router/routes/defines/tire_shelfs'
import tireSizes from '@/router/routes/defines/tire_sizes'
import tireStatuses from '@/router/routes/defines/tire_statuses'
import tireTypes from '@/router/routes/defines/tire_types'
import fuels from '@/router/routes/defines/fuels'
import gears from '@/router/routes/defines/gears'
import colors from '@/router/routes/defines/colors'
import meets from '@/router/routes/defines/meets'
import models from '@/router/routes/defines/models'
import meetGroups from '@/router/routes/defines/meet_groups'
import interviewTypes from '@/router/routes/defines/interview_types'
import cities from '@/router/routes/defines/cities'
import damageTrackingStatus from '@/router/routes/defines/damage_tracking_status'
import damageTrackingInsurances from '@/router/routes/defines/damage_tracking_insurances'
import damageTrackingTypes from '@/router/routes/defines/damage_tracking_types'
import damageRepairCompanies from '@/router/routes/defines/damage_repair_companies'
import damageTrackingDocumentTypes from '@/router/routes/defines/damage_tracking_documents'
import damageRepairStatuses from '@/router/routes/defines/damage_repair_status'
import districts from '@/router/routes/defines/districts'
import locations from '@/router/routes/defines/locations'
import kdvRates from '@/router/routes/defines/kdv_rates'
import marketingMobileApp from '@/router/routes/marketing/mobile_app'
import otvRates from '@/router/routes/defines/otv_rates'
import sectors from '@/router/routes/defines/sectors'
import payments from '@/router/routes/defines/payments'
import serviceTypes from '@/router/routes/defines/service_types'
import serviceStatuses from '@/router/routes/defines/service_statuses'
import serviceSmsTypes from '@/router/routes/defines/service_sms_types'
import crmSubjects from '@/router/routes/defines/crm_subjects'
import crmFeedbackTypes from '@/router/routes/defines/crm_feedback_types'
import crmLevels from '@/router/routes/defines/crm_levels'
import crmStatuses from '@/router/routes/defines/crm_statuses'
import crmApplySources from '@/router/routes/defines/crm_apply_sources'
import userTypes from '@/router/routes/defines/user_types'
import smtpConfig from '@/router/routes/core/smtp'
import departments from '@/router/routes/defines/departments'
import jobs from '@/router/routes/defines/jobs'
import insuranceCompanies from '@/router/routes/defines/insurance_companies'
import interestRate from '@/router/routes/defines/interest_rate'
import customers from '@/router/routes/customers'
import cars from '@/router/routes/cars'
import swapcars from '@/router/routes/swapcars'
import crmRequests from '@/router/routes/crm'
import crm from '@/router/routes/reports/crm'
import sales from '@/router/routes/reports/sales'
import rental from '@/router/routes/reports/rental'
import marketingReports from '@/router/routes/reports/marketing'
import users from '@/router/routes/core/users'
import serviceCounters from '@/router/routes/external_data/service_counters'
import csi from '@/router/routes/external_data/csi'
import damageTracking from '@/router/routes/damage_tracking/index'
import hrNotifications from '@/router/routes/hr/notifications'
import hrEmployees from '@/router/routes/hr/employees'
import hrCareerForm from '@/router/routes/hr/careerform'
import marketingAdTypes from '@/router/routes/marketing/ad_types'
import marketingAdPlatforms from '@/router/routes/marketing/ad_platforms'
import marketingAdForms from '@/router/routes/marketing/ad_forms'
import marketingAds from '@/router/routes/marketing/ads'
import meetingCalendar from '@/router/routes/meeting_calendar'
import birthdaySms from '@/router/routes/marketing/birthday_sms'
import campaignSms from '@/router/routes/marketing/campaign_sms'
import batchInterviews from '@/router/routes/marketing/batch_interviews'
import purchaseDefines from '@/router/routes/purchase/defines'
import permitRequest from '@/router/routes/permit_request'
import alerts from '@/router/routes/alerts'
import insuranceRenewal from '@/router/routes/insurance/renewal'
import insuranceInterviewBatch from '@/router/routes/insurance/interview_batch'
import insuranceReminder from '@/router/routes/insurance/reminder'
import interviews from '@/router/routes/interviews'
import interviewOpens from '@/router/routes/interviews/opens'
import offers from '@/router/routes/offers'
import batteryOffers from '@/router/routes/battery_offers'
import purchasingRequests from '@/router/routes/purchase/requests'
import purchasingOrders from '@/router/routes/purchase/orders'
import portalAwards from '@/router/routes/portal/awards'
import portalNotices from '@/router/routes/portal/notices'
import portalQuoteOfDays from '@/router/routes/portal/quote_of_days'
import portalMeals from '@/router/routes/portal/meals'
import portalEvents from '@/router/routes/portal/events'
import portalPolls from '@/router/routes/portal/polls'
import portalReportage from '@/router/routes/portal/reportage'
import portalCareer from '@/router/routes/portal/career'
import portalGallery from '@/router/routes/portal/gallery'
import portalEduCalendar from '@/router/routes/portal/edu_calendar'
import portalEducations from '@/router/routes/portal/educations'
import portalFixedEducations from '@/router/routes/portal/fixed_educations'
import portalDocuments from '@/router/routes/portal/documents'
import contractedCompanies from '@/router/routes/portal/contracted_companies'
import organizationCharts from '@/router/routes/portal/organization_charts'
import saleOrderForms from '@/router/routes/sales'
import surveys from '@/router/routes/surveys'
import i2Sales from '@/router/routes/i2sales'
import realty from '@/router/routes/realty'
import insuranceReport from '@/router/routes/reports/insurance'
import serviceReport from '@/router/routes/reports/service'
import spareParts from '@/router/routes/spare_parts'
import csiReports from '@/router/routes/reports/csi'
import service from '@/router/routes/service'
import rentalStocks from '@/router/routes/rental/stocks'
import rentalContracts from '@/router/routes/rental/contracts'
import rentalPages from '@/router/routes/rental/rentalPages'
import rentalVehiclePark from '@/router/routes/rental/vehiclePark'
import webformCallform from '@/router/routes/webforms/callform'
import webformContact from '@/router/routes/webforms/contact'
import webformI2bidform from '@/router/routes/webforms/i2bidform'
import webformI2buyform from '@/router/routes/webforms/i2buyform'
import webformI2saleform from '@/router/routes/webforms/i2saleform'
import webformTestdrive from '@/router/routes/webforms/testdrive'
import digitalResponse from '@/router/routes/webforms/digitalResponse'
import guides from '@/router/routes/guides'
import customerDeduplication from '@/router/routes/manager_tools'
import userSection from '@/router/routes/defines/user_section'
/* Service Work Orders */
import swoDefinesSections from '@/router/routes/service_work_orders/defines/sections'
import swoDefinesWorkers from '@/router/routes/service_work_orders/defines/workers'
import swoDefinesWorks from '@/router/routes/service_work_orders/defines/works'
import serviceWorkOrders from '@/router/routes/service_work_orders/index'

/* Sparepart Stock Groups */
import sparepartStockGroups from '@/router/routes/defines/SparepartStockGroups'
import sparepartStockCards from '@/router/routes/spare_parts/stock_cards'
import sparepartOrders from '@/router/routes/spare_parts/sparepart_orders'

/*
Marketing Request
 */
import marketingRequest from '@/router/routes/marketing/marketing_request'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...swapBrands,
    ...brands,
    ...banks,
    ...tireBrands,
    ...tireShelfs,
    ...tireSizes,
    ...tireStatuses,
    ...tireTypes,
    ...fuels,
    ...gears,
    ...colors,
    ...meets,
    ...models,
    ...swapModels,
    ...meetGroups,
    ...interviewTypes,
    ...cities,
    ...damageTrackingStatus,
    ...damageTrackingInsurances,
    ...damageTrackingTypes,
    ...damageRepairCompanies,
    ...damageTrackingDocumentTypes,
    ...damageRepairStatuses,
    ...districts,
    ...locations,
    ...kdvRates,
    ...marketingMobileApp,
    ...otvRates,
    ...sectors,
    ...payments,
    ...serviceTypes,
    ...serviceSmsTypes,
    ...crmSubjects,
    ...crmFeedbackTypes,
    ...crmLevels,
    ...crmStatuses,
    ...crmApplySources,
    ...customers,
    ...cars,
    ...swapcars,
    ...crmRequests,
    ...crm,
    ...sales,
    ...rental,
    ...marketingReports,
    ...users,
    ...userTypes,
    ...departments,
    ...jobs,
    ...insuranceCompanies,
    ...interestRate,
    ...serviceCounters,
    ...csi,
    ...damageTracking,
    ...hrNotifications,
    ...hrEmployees,
    ...hrCareerForm,
    ...marketingAdTypes,
    ...marketingAdPlatforms,
    ...marketingAdForms,
    ...marketingAds,
    ...meetingCalendar,
    ...offers,
    ...batteryOffers,
    ...permitRequest,
    ...portalAwards,
    ...portalNotices,
    ...purchaseDefines,
    ...alerts,
    ...insuranceRenewal,
    ...insuranceInterviewBatch,
    ...insuranceReminder,
    ...interviews,
    ...interviewOpens,
    ...purchasingRequests,
    ...purchasingOrders,
    ...portalQuoteOfDays,
    ...portalMeals,
    ...portalEvents,
    ...portalPolls,
    ...portalReportage,
    ...portalCareer,
    ...portalGallery,
    ...portalEduCalendar,
    ...portalEducations,
    ...portalFixedEducations,
    ...portalDocuments,
    ...contractedCompanies,
    ...organizationCharts,
    ...saleOrderForms,
    ...surveys,
    ...realty,
    ...i2Sales,
    ...insuranceReport,
    ...serviceReport,
    ...spareParts,
    ...birthdaySms,
    ...campaignSms,
    ...batchInterviews,
    ...csiReports,
    ...service,
    ...serviceStatuses,
    ...rentalStocks,
    ...rentalContracts,
    ...rentalPages,
    ...rentalVehiclePark,
    ...webformCallform,
    ...webformContact,
    ...webformI2bidform,
    ...webformI2buyform,
    ...webformI2saleform,
    ...webformTestdrive,
    ...digitalResponse,
    ...smtpConfig,
    ...guides,
    ...swoDefinesSections,
    ...swoDefinesWorkers,
    ...swoDefinesWorks,
    ...serviceWorkOrders,
    ...customerDeduplication,
    ...userSection,
    ...sparepartStockGroups,
    ...sparepartStockCards,
    ...sparepartOrders,
    ...marketingRequest,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
