import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    meals: [],
    items: [],
    importList: [],
    mealsCount: 0,
    meal: {
      id: null,
      mdate: null,
      mealList: [
        {
          title: null,
        },
      ],
      submitStatus: false,
    },
    mealSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getMeals(state) {
      return state.meals
    },
    getItems(state) {
      return state.items
    },
    importList(state) {
      return state.importList
    },
    getMealsCount(state) {
      return state.mealsCount
    },
    getMeal(state) {
      return state.meal
    },
    getMealSaveStatus(state) {
      return state.mealSave
    },
  },
  mutations: {
    MEALS_LIST(state, data) {
      state.meals = data
    },
    ITEMS_LIST(state, data) {
      state.items = data
    },
    SET_IMPORT_LIST(state, data) {
      state.importList = data
    },
    MEAL_VIEW(state, data) {
      state.meal = data
    },
    MEALS_COUNT(state, data) {
      state.mealsCount = data
    },
    MEAL_SAVE(state, data) {
      state.mealSave = data
    },
    MEAL_RESET(state, data) {
      if (data.status) {
        state.meal = {
          id: null,
          mdate: null,
          mealList: [
            {
              title: null,
            },
          ],
          submitStatus: false,
        }
      }
    },
  },
  actions: {
    itemsList({ commit }) {
      axiosIns
        .post('/portal/meals/itemsList')
        .then(response => {
          commit('ITEMS_LIST', response.data)
        })
        .catch(error => console.error(error))
    },
    mealsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/meals', data)
        .then(response => {
          commit('MEALS_LIST', response.data.data)
          commit('MEALS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    mealView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_meals.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/meals', data)
        .then(response => {
          commit('MEAL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    mealSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/portal/meals/saveData', data)
        .then(response => {
          commit('MEAL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    mealBatchSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/portal/meals/saveBatch', data)
        .then(response => {
          commit('MEAL_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    mealDelete({ commit }, id) {
      const data = JSON.stringify({ id })
      axiosIns
        .post('/portal/meals/deleteData', data)
        .then(response => {
          commit('MEAL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    mealReset({ commit }) {
      commit('MEAL_RESET', { status: true })
    },
  },
}
