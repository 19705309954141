export default [
  {
    path: '/damage-tracking-documents',
    name: 'DamageDocumentType',
    component: () => import('@/views/Defines/Damage_tracking_document_types/Index.vue'),
    meta: {
      pageTitle: 'Hasar Takip Doküman Tipleri',
      breadcrumb: [
        {
          text: 'Hasar Takip Doküman Tipleri',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_DOCUMENT',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking-documents/add',
    name: 'DamageDocumentTypeAdd',
    component: () => import('@/views/Defines/Damage_tracking_document_types/Add.vue'),
    meta: {
      pageTitle: 'Doküman Tipi Ekle',
      breadcrumb: [
        {
          text: 'Hasar Takip Doküman Tipleri',
          to: '/damage-tracking-documents',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_DOCUMENT',
      action: 'read',
    },
  },
  {
    path: '/damage-tracking-documents/edit/:id',
    name: 'DamageDocumentTypeEdit',
    component: () => import('@/views/Defines/Damage_tracking_document_types/Edit.vue'),
    meta: {
      pageTitle: 'Doküman Tipi Güncelle',
      breadcrumb: [
        {
          text: 'Hasar Takip Doküman Tipleri',
          to: '/damage-tracking-documents',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_DOCUMENT',
      action: 'read',
    },
  },
]
