export default [
  {
    path: '/insurance/renewal/periods',
    name: 'InsuranceRenewalPeridos',
    component: () => import('@/views/Insurance/Renewal/Periods/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal',
          active: false,
        },
        {
          text: 'Dönemler',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_PERIODS',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal/periods/view/:id',
    name: 'InsuranceRenewalPeridoView',
    component: () => import('@/views/Insurance/Renewal/Periods/View.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal',
          active: false,
        },
        {
          text: 'Dönemler',
          to: '/insurance/renewal/periods',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_PERIODS',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal/import',
    name: 'InsuranceRenewalImport',
    component: () => import('@/views/Insurance/Renewal/Import/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          to: '/insurance/renewal/periods',
          active: false,
        },
        {
          text: 'Liste Aktarım',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_DATA_IMPORT',
      action: 'read',
    },
  },
  {
    path: '/insurance/renewal',
    name: 'InsuranceRenewals',
    component: () => import('@/views/Insurance/Renewal/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          text: 'Yenileme Listesi',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_LIST',
      action: 'read',
    },
  },
  {
    path: '/insurance/pending-delete-requests',
    name: 'deleteRenewals',
    component: () => import('@/views/Insurance/Renewal/deleteRequests/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          to: '/insurance/renewal',
          text: 'Yenileme Listesi',
          active: false,
        },
        {
          text: 'Kayıt Silme İstekleri',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_DELETE_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/insurance/pending-delete-requests/:id_deleted', // bildirim bugundan dolayı. Başka işlevi  yok
    name: 'deleteRenewals',
    component: () => import('@/views/Insurance/Renewal/deleteRequests/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          to: '/insurance/renewal',
          text: 'Yenileme Listesi',
          active: false,
        },
        {
          text: 'Kayıt Silme İstekleri',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_DELETE_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/insurance/pending-edit-requests',
    name: 'editRenewals',
    component: () => import('@/views/Insurance/Renewal/editRequests/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          to: '/insurance/renewal',
          text: 'Yenileme Listesi',
          active: false,
        },
        {
          text: 'Kayıt Düzenleme İstekleri',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_EDIT_REQUESTS',
      action: 'read',
    },
  },
  {
    path: '/insurance/pending-edit-requests/:id_edited', // bildirim bugundan dolayı. Başka işlevi  yok
    name: 'editRenewals',
    component: () => import('@/views/Insurance/Renewal/editRequests/Index.vue'),
    meta: {
      pageTitle: 'Sigorta',
      breadcrumb: [
        {
          to: '/insurance/renewal',
          text: 'Yenileme Listesi',
          active: false,
        },
        {
          text: 'Kayıt Düzenleme İstekleri',
          active: true,
        },
      ],
      resource: 'INSURANCE_RENEWAL_EDIT_REQUESTS',
      action: 'read',
    },
  },
]
