import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    SaleSurvey: {
      totals: {},
      statuses: [],
      users: [],
      detail: [],
    },
  },
  getters: {
    getSaleSurveyReport(state) {
      return state.SaleSurvey
    },
  },
  mutations: {
    SALE_SURVEY_REPORT(state, data) {
      state.SaleSurvey = data
    },
    RESET_SALE_SURVEY_REPORT(state) {
      state.SaleSurvey = {
        totals: {},
        statuses: [],
        users: [],
        detail: [],
      }
    },
  },
  actions: {
    SaleSurveyReport({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/reports/crm/surveys/inallar', data)
        .then(response => {
          commit('SALE_SURVEY_REPORT', response.data)
        })
        .catch(error => console.error(error))
    },
    resetSaleSurveyReport({ commit }) {
      commit('RESET_SALE_SURVEY_REPORT')
    },
  },
}
