import moment from 'moment'
import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_contracts'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      contract_no: null,
      sdate: null,
      edate: null,
      contract_date: moment().format('YYYY-MM-DD'),
      status: null,
      notes: null,
      id_com_rental_stocks: [],
      id_com_rental_contract_status: null,
      id_com_customer: null,
      rental_stocks: [],
      upload_file: null,
      upload_files: [],
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    searchData: {
      keyword: null,
    },
    detailFilter: {
      sdate: null,
      edate: null,
      id_com_rental_contract_status: '1',
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    searchData(state) {
      return state.searchData
    },
    detailFilter(state) {
      return state.detailFilter
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        contract_no: null,
        sdate: null,
        edate: null,
        contract_date: moment().format('YYYY-MM-DD'),
        status: null,
        notes: null,
        id_com_rental_stocks: [],
        id_com_rental_contract_status: null,
        id_com_customer: null,
        rental_stocks: [],
        upload_file: null,
        upload_files: [],
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_rental_contracts.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const formData = new FormData()
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in postData) {
        if (key !== 'rental_stocks') {
          if (key === 'id_com_rental_stocks') {
            if (Array.isArray(postData[key])) {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const key2 in postData[key]) {
                formData.append('id_com_rental_stocks[]', postData[key][key2])
              }
            } else {
              formData.append('id_com_rental_stocks', postData[key])
            }
          } else if (key === 'upload_files') {
            if (Array.isArray(postData[key])) {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const key3 in postData[key]) {
                formData.append('upload_files[]', postData[key][key3])
              }
            }
          } else if (key === 'upload_bond_files') {
            if (Array.isArray(postData[key])) {
              // eslint-disable-next-line guard-for-in,no-restricted-syntax
              for (const key4 in postData[key]) {
                formData.append('upload_bond_files[]', postData[key][key4])
              }
            }
          } else {
            formData.append(key, postData[key])
          }
        }
      }
      axiosIns
        .post(`${ROOT_URL}/saveData`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    uploadContractFile({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('upload_file', postData.upload_file)
      return axiosIns
        .post(`${ROOT_URL}/uploadContractFile`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
