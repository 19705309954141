export default [
  {
    path: '/service/work-orders',
    name: 'ServiceWorkOrders',
    component: () => import('@/views/Service_work_orders/Orders/Index.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Emirleri',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/open',
    name: 'ServiceWorkOrdersOpens',
    component: () => import('@/views/Service_work_orders/Orders/OpenIndex.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'Devam Eden İş Emirleri',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/add',
    name: 'ServiceWorkOrderAdd',
    component: () => import('@/views/Service_work_orders/Orders/Add.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Emirleri',
          to: '/service/work-orders',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS_ADD',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/edit/:id',
    name: 'ServiceWorkOrderEdit',
    component: () => import('@/views/Service_work_orders/Orders/Edit.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Emirleri',
          to: '/service/work-orders',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS_EDIT',
      action: 'read',
    },
  },
  {
    path: '/service/work-orders/view/:id',
    name: 'ServiceWorkOrderView',
    component: () => import('@/views/Service_work_orders/Orders/View.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Emirleri',
          to: '/service/work-orders',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS_VIEW',
      action: 'read',
    },

  },
  {
    path: '/service/work-orders/worker-view/:id',
    name: 'ServiceWorkOrderView',
    component: () => import('@/views/Service_work_orders/Orders/WorkerView.vue'),
    meta: {
      pageTitle: 'Servis İş Emirleri',
      breadcrumb: [
        {
          text: 'İş Emirleri',
          to: '/service/work-orders',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SERVICE_WORK_ORDERS_VIEW',
      action: 'read',
    },

  },
]
