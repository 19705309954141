import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    i2Stocks: [],
    i2StocksCount: 0,
    i2Stock: {},
    i2StockSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getI2Stocks(state) {
      return state.i2Stocks
    },
    getI2StocksCount(state) {
      return state.i2StocksCount
    },
    getI2Stock(state) {
      return state.i2Stock
    },
    getI2StockSaveStatus(state) {
      return state.i2StockSave
    },
  },
  mutations: {
    SET_I2STOCKS(state, data) {
      state.i2Stocks = data
    },
    SET_I2STOCK(state, data) {
      state.i2Stock = data
    },
    SET_I2STOCK_COUNT(state, data) {
      state.i2StocksCount = data
    },
    SET_I2STOCK_SAVE(state, data) {
      state.i2StockSave = data
    },
  },
  actions: {
    i2Stocks({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/i2stock', data)
        .then(response => {
          commit('SET_I2STOCKS', response.data.data)
          commit('SET_I2STOCK_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    i2Stock({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_i2stock.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/i2stock', data)
        .then(response => {
          commit('SET_I2STOCK', response.data.data)
        })
        .catch(error => console.error(error))
    },
    i2StockSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/i2stock/saveData', data)
        .then(response => {
          commit('SET_I2STOCK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
