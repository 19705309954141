export default [
  {
    path: '/damage-repair-statuses',
    name: 'DamageRepairStatuses',
    component: () => import('@/views/Defines/Damage_repair_status/Index.vue'),
    meta: {
      pageTitle: 'Tamir Durumları',
      breadcrumb: [
        {
          text: 'Tamir Durumları',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_REPAIR_STATUS',
      action: 'read',
    },
  },
  {
    path: '/damage-repair-statuses/add',
    name: 'DamageRepairStatusesAdd',
    component: () => import('@/views/Defines/Damage_repair_status/Add.vue'),
    meta: {
      pageTitle: 'Tamir Durumu Ekle',
      breadcrumb: [
        {
          text: 'Tamir Durumları',
          to: '/damage-repair-statuses',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_REPAIR_STATUS',
      action: 'read',
    },
  },
  {
    path: '/damage-repair-statuses/edit/:id',
    name: 'DamageRepairStatusesEdit',
    component: () => import('@/views/Defines/Damage_repair_status/Edit.vue'),
    meta: {
      pageTitle: 'Tamir Durumu Güncelle',
      breadcrumb: [
        {
          text: 'Tamir Durumları',
          to: '/damage-repair-statuses',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_REPAIR_STATUS',
      action: 'read',
    },
  },
]
