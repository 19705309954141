export default [
  {
    path: '/damage-repair-companies',
    name: 'DamageRepairCompanies',
    component: () => import('@/views/Defines/Damage_repair_companies/Index.vue'),
    meta: {
      pageTitle: 'Araç Tamir Şirketleri',
      breadcrumb: [
        {
          text: 'Araç Tamir Şirketleri',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_COMPANY',
      action: 'read',
    },
  },
  {
    path: '/damage-repair-companies/add',
    name: 'DamageRepairCompaniesAdd',
    component: () => import('@/views/Defines/Damage_repair_companies/Add.vue'),
    meta: {
      pageTitle: 'Araç Tamir Şirketi Ekle',
      breadcrumb: [
        {
          text: 'Araç Tamir Şirketleri',
          to: '/damage-repair-companies',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_COMPANY',
      action: 'read',
    },
  },
  {
    path: '/damage-repair-companies/edit/:id',
    name: 'DamageRepairCompaniesEdit',
    component: () => import('@/views/Defines/Damage_repair_companies/Edit.vue'),
    meta: {
      pageTitle: 'Araç Tamir Şirketi Güncelle',
      breadcrumb: [
        {
          text: 'Araç Tamir Şirketleri',
          to: '/damage-repair-companies',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'DEFINES_DAMAGE_TRACKING_COMPANY',
      action: 'read',
    },
  },
]
