import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    grandTotal: {
      total: 0,
      connected: 0,
      days_0_30: 0,
      days_31_60: 0,
      days_61: 0,
      value_date: 0,
    },
    filterData: {
      sdate: null,
      edate: null,
      id_com_brand: null,
      MODEL_YILI: null,
      connected_status: true,
    },
    loadingReport: true,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getGrandTotal(state) {
      return state.grandTotal
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_GRAND_TOTAL(state, data) {
      state.grandTotal = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/sales/stocks', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_GRAND_TOTAL', response.data.grandTotal)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
