import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: [],
    dataCount: 0,
    filterData: {
      id_com_brand: [],
      id_com_model: [],
      id_com_user: [],
      keyword: null,
      status: [],
      detailSearch: false,
      statuses: [
        {
          id: 1,
          title: 'Satış',
        },
        {
          id: 2,
          title: 'Bağlantı',
        },
      ],
      sdate: null,
      edate: null,
    },
    loadingReport: false,
  },
  getters: {
    getReport(state) {
      return state.reportData
    },
    getReportDataCount(state) {
      return state.dataCount
    },
    getFilterData(state) {
      return state.filterData
    },
    getLoading(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    SET_RESET_FILTER_DATA(state, data) {
      state.filterData = {
        id_com_brand: [],
        id_com_model: [],
        id_com_user: [],
        status: [],
        statuses: [
          {
            id: 1,
            title: 'Satış',
          },
          {
            id: 2,
            title: 'Bağlantı',
          },
        ],
        keyword: null,
        detailSearch: false,
        sdate: null,
        edate: null,
      }
    },
  },
  actions: {
    report({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/sales/SalesReport', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data.data)
          commit('SET_REPORT_DATA_COUNT', response.data.count)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}
